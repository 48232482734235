
::-webkit-scrollbar {
  width: 14px;
}
::-webkit-scrollbar-track {
  background: #c9c9c9;
  border-radius: 5px;
}
::-webkit-scrollbar-thumb {
  background: #004659;
  border-radius: 5px;
}
::selection {
  color: #004659;
  background: #eeece9de;
}

nav .navbar-toggler {
  color: #ebe6dcde !important;
  border: 1px solid #ebe6dcde;
  background-color: #ebe6dcde;
}
nav button .navbar-toggler-icon{
  color: #ebe6dcde !important;
}

body{
  font-family: "Truculenta", sans-serif;
}
.slick-slider{
  height: 100%;
}
.slick-list{
  height: 100%;
}
.slick-track{
  height: 100%;
}
.slick-slide{
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}
.slick-dots li button:before{
  color: white !important;
 font-size: 17px;
 top: -50px;
}
.nav-link:focus{
  color: #eeece9de;
}
li{
 list-style: none;
 margin-bottom: 10px;
}
ul{
  padding: 0;
}
.mainColor{
 color: #004659; 
}
.fa-location-dot{
  position: relative;
  left: 0;
  animation: location 1s infinite;
}
.fa-envelope{
  animation: envelope 1s infinite;
}
.fa-phone{
  animation: phone 1s infinite;
 }

@keyframes location {
  0% {
    top: 0px;
  }
  50% {
    top: 5px;
  }
  100%{
    top: 0px;
  }
}

@keyframes envelope {
  0% {
   transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100%{
    transform: scale(1);
  }
}

@keyframes phone {
  0% {
   transform: rotate(0deg);
  }
  50% {
    transform: rotate(15deg);
  }
  100%{
    transform: rotate(0deg);
  }
}
