
.loginComponent{
    width: 100%;
    height: 100vh;
    background-image: linear-gradient(rgba(0, 55, 82, 0.263) 0% 100%), url(../../assets/images/signBg.jpg);
    background-position: 100% 100%;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
}

.formContent{
 display: flex;
 align-items: center;
 justify-content: center;
}

.loginComponent form{
    width: 90%;
    /* margin: auto; */
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 7px;
    box-shadow: 0px 5px 22px 17px #0046594c;
     padding: 25px;
}
.loginComponent form input{
 background-color: rgba(0, 12, 30, 0.45);
 color: white;
 width: 100%;
 padding: 5px 5px;
}

.loginComponent form input::placeholder{
    color: white;
}

.loginComponent form input:focus{
    background-color: rgba(0, 12, 30, 0.45);
    color: white;
    border-color: white;
    box-shadow:none;
}
.loginBtn{
    background-color: #004659;
    border: 1px solid white;
    color: white;
    width: 100%;
    padding: 6px 10px;
}
.loginBtn:hover{
    background-color: #002e3b;
    border: 1px solid white;
    color: white;
}