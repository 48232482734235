.homePackages{
    margin-block: 60px;
    background-image:linear-gradient(#00303ecb 0% 100%), url(../../assets/images/PackagesBG.jpg);
    background-size: cover;
    background-position: center;
    padding: 100px;
}
.packageCard{
    border: 1px solid black;
    padding: 30px 0 20px 0;
    background-color: #fff;
    transition: all 0.2s ease-in-out;
}
.packageCard:hover{
    transform: translateY(-10px);
}
.type{
    font-size: 32px;
    color: #555555;
}
.price{
    font-size: 70px;
    color: #004659;
}

@media screen and (min-width: 300px) and (max-width: 600px){
    .homePackages{
        padding: 50px 30px 50px 30px;
    }
    
    
}