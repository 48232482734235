.FeedbackComponent {
    width: 100%;
    padding-block: 30px;
    min-height: 500px;
    background-image: linear-gradient(#004659ea 0% 100%), url(../../assets/images/VBG.jpg);
    background-size: cover;
    background-position: center center;
    background-attachment: fixed;
}
.headingADesign {
    background-color: #e3d8c1d0;
    position: relative;
    width: 165px;
    height: 2px;
    top: 7px;
    left: 43%;
    margin-bottom: 30px;
}
.headingADesign::after {
    content: '';
    width: 70px;
    height: 2px;
    position: absolute;
    top: -8px;
    left: 50px;
    background-color: #e3d8c1d0;
}
.headingADesign:before {
    content: '';
    width: 70px;
    height: 2px;
    position: absolute;
    top: 8px;
    left: 50px;
    background-color: #e3d8c1d0;
}
.carousel {
    margin-inline: 15px;
    height: 400px;
    border-radius: 10px;
}
.slider2 {
    display: none;
}
.carousel img {
    width: 70px;
    height: 70px;
    border-radius: 50%;
    margin-bottom: 10px;
    border: 8px solid #e3d8c1d0;
}
.feedbackPara {
    padding: 5px;
    background-color: #cac5bc;
    margin: 10px 10px 50px 10px;
    text-align: center;
    position: relative;
    color: #0c2320;
    font-weight: 500;
    font-size: 18px;
    border-radius: 2px;
}
.feedbackPara::after {
    content: '';
    position: absolute;
    top: -25%;
    left: 47%;
    width: 50px;
    height: 50px;
    background-color: rgb(255 228 196 / 62%);
    transform: rotate(45deg);
    z-index: -1;
}

@media screen and (min-width: 300px) and (max-width: 600px) {
    .slider1 {
        display: none;
    }

    .slider2 {
        display: block;
    }

    .headingADesign {
        left: 27%;
    }

    .feedbackPara::after {
        top: -9%;
        left: 43%;
    }

    .feedbackPara {
        font-size: 13px;
    }

}