.coursesComponent {
    padding-bottom: 60px;
}
.courseCard {
    padding: 20px 0 30px 0;
    box-shadow: 0px 5px 13px 0 #0046594c;
    transition: transform 0.3s ease-in-out;
}
.courseCard img {
    transition: transform 0.7s ease-in-out;
}
.courseCard:hover img {
    transform: scale(1.2);
}
.courseCard:hover {
    transform: translateY(10px)
}
.headingCDesign {
    background-color: #00617b;
    position: relative;
    width: 165px;
    height: 2px;
    top: -32px;
    left: 564px;
}
.headingCDesign:after {
    content: '';
    width: 70px;
    height: 2px;
    position: absolute;
    top: -8px;
    left: 50px;
    background-color: #00617b;
}
.headingCDesign:before {
    content: '';
    width: 70px;
    height: 2px;
    position: absolute;
    top: 8px;
    left: 50px;
    background-color: #00617b;
}
.bookBtn {
    background-color: #004659;
    border: 2px solid transparent;
    color: white;
    transition: all 0.3s ease-in-out;
    padding: 8px 12px
}
.bookBtn:hover {
    background-color: transparent;
    border: 2px solid #004659;
    color: #004659;
}


@media screen and (min-width: 300px) and (max-width: 600px) {

    .headingCDesign {
        left: 28%;
    }

    .coursesComponent {
        padding-bottom: 0px;
    }
}