nav{
    /* background-color: #154C46; */
    background-color: #004659;
}
nav img{
 width: 65px;
 height: 65px;
}
.textColor{
  color: #e3d8c1de;
  transition: all 0.3s ease-in-out;
}
.textColor:hover{
  color: #e3d8c1de;
  transform: translateY(-6px);
}
.navLink{
    color: #eeece9de;
    transition: all 0.3s ease-in-out;
    font-size: 19px;
}
.navLink:hover{
 color: #e3d8c1de;
 transform: translateY(-6px);
}
