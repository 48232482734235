.demoComponent {
    padding-bottom: 60px;
    margin-top: 80px;
}
.demoComponent h3 {
    font-size: 50px;
    font-weight: 600;
    text-align: center;
}
.demoComponent h3 span {
    color: rgb(196 196 121);
}
.headingVDesign {
    background-color: #004659;
    position: relative;
    width: 165px;
    height: 1px;
    top: -32px;
    left: 564px;
}
.headingVDesign:after {
    content: '';
    width: 70px;
    height: 1px;
    position: absolute;
    top: -8px;
    left: 50px;
    background-color: #004659;
}
.headingVDesign:before {
    content: '';
    width: 70px;
    height: 1px;
    position: absolute;
    top: 8px;
    left: 50px;
    background-color: #004659;
}
.translateBtn {
    padding: 5px 45px;
    background-color: transparent;
    border: 2px solid #004659dd;
    transition: all 0.3s ease-in-out;
    font-size: 20px;
    font-weight: 600;
}
.translateBtn:hover {
    background-color:  #004659dd;
    border: 2px solid transparent;
    transform: translateY(8px);
    color: #EEEEEE;
}
.registerSection p {
    font-size: 20px;
    font-weight: 500;
}
.registerSection form {
    box-shadow: 0 0 14px 0px #00000038;
    padding: 30px;
}
.registerSection form input,
.registerSection form select {
    border: transparent;
    background-color: #EEEEEE;
    padding: 6px;
    width: 100%;
    outline: none;
}
.registerSection form textarea {
    background-color: #EEEEEE;
    padding: 6px;
    width: 100%;
    outline: none;
    border: none;
}
.messageBtn {
    padding: 7px 15px;
    font-size: 18px;
    margin-top: 15px;
    background-color: #004659;
    border: 2px solid transparent;
    color: white;
    transition: all 0.3s ease-in-out;
}
.messageBtn:hover {
    background-color: transparent;
    border: 2px solid #004659;
    color: #004659;
}

@media screen and (min-width: 300px) and (max-width: 600px) {
    .demoComponent {
        margin-top: 30px;
    }

}