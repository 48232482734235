.aboutComponent {
    margin-bottom: 70px;
    margin-top: 20px;
}
.headingADesign {
    background-color:  #00617b;
    position: relative;
    width: 165px;
    height: 2px;
    top: -32px;
    left: 44%;
    margin-bottom: 30px;
}
.headingADesign::after {
    content: '';
    width: 70px;
    height: 2px;
    position: absolute;
    top: -8px;
    left: 50px;
    background-color: #00617b;
}
.headingADesign:before {
    content: '';
    width: 70px;
    height: 2px;
    position: absolute;
    top: 8px;
    left: 50px;
    background-color: #00617b;
}

@media screen and (min-width: 300px) and (max-width: 600px) {
    .headingADesign {
        left: 29%;
    }

}