.aboutComponent .section1 {
    padding: 40px 0 30px 0;
}

.contactBtn {
    background-color: #004659;
    border: 2px solid transparent;
    color: white;
    transition: all 0.3s ease-in-out;
    font-family: "Poppins", sans-serif;
    margin-top: 30px;
    padding: 8px 12px;
}

.contactBtn:hover {
    background-color: transparent;
    border: 2px solid #004659;
    color: #004659;
}

.section2 {
    background-color: #004659;
    padding: 40px 0;
    color: white;
    margin-bottom: 30px;
}

.aboutComponent .section3 {
    margin-bottom: 60px;
}

.aboutComponent h2 {
    font-size: 50px;
}

.aboutComponent h2 span {
    color: rgb(196 196 121);
}

.belowTitle {
    font-family: "Poppins", sans-serif;
    margin-bottom: 10px;
}

.aboutComponent p {
    color: rgb(44, 44, 44);
    font-size: 17px;
    font-family: "Poppins", sans-serif;
    line-height: 35px;
}

.aboutComponent p span {
    color: #004659;
    font-weight: 600;
}

.topPart {
    text-align: center;
}

.card {
    text-align: center;
    box-shadow: 0 0 9px 0px #64646426;
    border-radius: 2px;
    transition: all 0.2s ease-in-out;
    padding: 10px 10px;
    cursor: pointer;
}

.card:hover {
    box-shadow: 0 0 9px 4px #69696984;
}

.section4 {
    margin-bottom: 80px;
    width: 85%;
}