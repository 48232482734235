.headComponent {
    width: 100%;
    height: calc(100vh - 65px);
    background-image: linear-gradient(rgba(0, 0, 0, 0.511) 0% 100%), url(../../assets/images/HeadBackG.jpg);
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    text-align: center;
}
.contactBtn {
    background-color: #004659;
    border: 2px solid transparent;
    color: white;
    transition: all 0.3s ease-in-out;
    padding: 8px 12px;
    font-size: 20px;
}
.contactBtn:hover {
    background-color: #0046591e;
    border: 2px solid #004659;
    color: white;
    transform: translateY(8px);
}
.aboutBtn {
    border: 2px solid #004659;
    color: white;
    transition: all 0.3s ease-in-out;
    background-color: #0046591e;
    padding: 8px 12px;
    font-size: 20px;
}
.aboutBtn:hover {
    background-color: #004659;
    border: 2px solid transparent;
    color: white;
    transform: translateY(8px);
}
.headTitle {
    font-size: 48px;
}
.headpara {
    font-size: 20px;
}


@media screen and (min-width: 300px) and (max-width: 600px) {
    .headTitle {
        font-size: 23px;
    }

    .headpara {
        font-size: 18px;
        padding: 0 40px;
    }

    .contactBtn,
    .aboutBtn {
        font-size: 15px;
    }

}