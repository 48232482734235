
.intro{
    background-image: linear-gradient(#004e63d9 0% 100%), url(../../assets/images/IntroBg.jpg);
    background-size: cover;
    background-position: 100%;
    padding: 40px 0;
}
.h100{
    min-height: 200px;
}
.introCard{
    transition: all 0.3s ease-in-out;
}
.introCard:hover{
    box-shadow: 0px 0px 25px 0 #000000;
}
